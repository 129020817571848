import { Controller } from "@hotwired/stimulus"

/*
<div class="mt-4"
  data-controller="tabs"
  data-tabs-active-tab="border-brand-red-500 whitespace-nowrap py-2 px-1 border-b-4 font-semibold text-brand-gray-900"
  data-tabs-inactive-tab="border-transparent whitespace-nowrap py-2 px-1 border-b-4 font-semibold text-brand-gray-500"
>
  <ul class="list-reset flex space-x-6">
    <li class="-mb-px" data-tabs-target="tab" data-action="click->tabs#change">
      <a href="#">Tab 1</a>
    </li>
    <li class="-mb-px" data-tabs-target="tab" data-action="click->tabs#change">
      <a href="#">Tab 2</a>
    </li>
  </ul>
  <div data-tabs-target="panel">
    Tab 1 body
  </div>
  <div data-tabs-target="panel">
    Tab 2 body
  </div>
</div>
*/

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    this.activeTabClasses = (this.data.get("activeTab") || "active").split(" ")
    this.inactiveTabClasses = (this.data.get("inactiveTab") || "inactive").split(" ")
    if (this.anchor) this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
    this.showTab()
  }

  change(event) {
    event.preventDefault()

    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      this.index = event.currentTarget.dataset.index

    // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id) {
      this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

    // Otherwise, use the index of the current target
    } else {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    window.dispatchEvent(new CustomEvent("tsc:tab-change"))
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]

      if (index === this.index) {
        panel.classList.remove("hidden")
        tab.classList.remove(...this.inactiveTabClasses)
        tab.classList.add(...this.activeTabClasses)

        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (tab.id) {
          location.hash = tab.id
        }
      } else {
        panel.classList.add("hidden")
        tab.classList.remove(...this.activeTabClasses)
        tab.classList.add(...this.inactiveTabClasses)
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", (value >= 0 ? value : 0))
    this.showTab()
  }

  get anchor() {
    return (document.URL.split("#").length > 1) ? document.URL.split("#")[1] : null
  }
}
