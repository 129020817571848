import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["url", "output", "notification"]

  async fetchHtml(event) {
    if (event) {
      event.preventDefault()
    }

    if (this.hasNotificationTarget) {
      this.notificationTarget.textContent = "Working..."
      this.notificationTarget.classList.remove("hidden")
    }

    const url = "/admin/social_media/oembed"
    const response = await get(url, { query: { url: this.urlTarget.value } })
    if (response.ok) {
      const responseBody = await response.json
      if (responseBody.html) {
        if (this.hasNotificationTarget) {
          this.notificationTarget.classList.add("hidden")
        }

        this.outputTarget.value = responseBody.html
      } else {
        if (this.hasNotificationTarget) {
          this.notificationTarget.textContent = responseBody.error
          this.notificationTarget.classList.remove("hidden")
        }
      }
    } else {
      if (this.hasNotificationTarget) {
        this.notificationTarget.textContent = "Backend exception occured."
        this.notificationTarget.classList.remove("hidden")
      }
    }
  }
}
