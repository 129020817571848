import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["markup"]

  trigger() {
    const chg = new Event("change",{ bubbles: true })
    this.markup.dispatchEvent(chg)
    // marked does not pick up js insertions, trigger a change
  }

  h1(event) {
    event.preventDefault()

    this.insert("# " + this.selectedValue)
  }

  h2(event) {
    event.preventDefault()

    this.insert("## " + this.selectedValue + " ")
  }

  h3(event) {
    event.preventDefault()

    this.insert("### " + this.selectedValue)
  }

  h4(event) {
    event.preventDefault()

    this.insert("#### " + this.selectedValue)
  }

  h5(event) {
    event.preventDefault()

    this.insert("##### " + this.selectedValue)
  }

  h6(event) {
    event.preventDefault()

    this.insert("###### " + this.selectedValue)
  }

  bold(event) {
    event.preventDefault()

    this.wrap("**", "Bold")
  }

  italic(event) {
    event.preventDefault()

    this.wrap("*", "Italic")
  }

  italicBold(event) {
    event.preventDefault()

    this.wrap("***", "BoldItalic")
  }

  backTick(event) {
    event.preventDefault()

    this.wrap("`")
  }

  ul(event) {
    event.preventDefault()

    this.insert("* " + this.selectedValue.replace(/\n/g,"\n* "))
  }

  ol(event) {
    event.preventDefault()

    this.insert("1. " + this.selectedValue.replace(/\n/g,"\n1. "))
  }

  anchor(event) {
    event.preventDefault()

    this.insert("[" + this.selectedValue + "]" + "(https://...)")
  }

  image(event) {
    event.preventDefault()

    this.insert("![" + this.selectedValue + "]" + "(path/to/img.jpg)")
  }

  backQuote(event) {
    event.preventDefault()

    this.insert(">" + this.selectedValue)
  }

  get markup() {
    return this.markupTarget
  }

  get range() {
    return this.getInputSelection(this.markup)
  }

  get selectedValue() {
    return this.markup.value.substring(this.range.start, this.range.end) || ""
  }

  insert(selected) {
    const len = this.markup.value.length
    this.markup.value = this.markup.value.substring(0, this.range.start) + selected + this.markup.value.substring(this.range.end, len)
    this.trigger()
  }

  wrap(wrapper, nowrapText) {
    const len = this.markup.value.length
    const wrapie = wrapper + (this.selectedValue || nowrapText || "") + wrapper
    this.markup.value = this.markup.value.substring(0, this.range.start) + wrapie + this.markup.value.substring(this.range.end, len)
    this.trigger()
  }

  getInputSelection(el) {
    let start = 0, end = 0, normalizedValue, range, textInputRange, len, endRange

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart
      end = el.selectionEnd
    } else {
      range = document.selection.createRange()

      if (range && range.parentElement() == el) {
        len = el.value.length
        normalizedValue = el.value.replace(/\r\n/g, "\n")
        // Create a working TextRange that lives only in the input
        textInputRange = el.createTextRange()
        textInputRange.moveToBookmark(range.getBookmark())
        // Check if the start and end of the selection are at the very end
        // of the input, since moveStart/moveEnd doesn"t return what we want
        // in those cases
        endRange = el.createTextRange()
        endRange.collapse(false)

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len
        } else {
          start = -textInputRange.moveStart("character", -len)
          start += normalizedValue.slice(0, start).split("\n").length - 1

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len
          } else {
            end = -textInputRange.moveEnd("character", -len)
            end += normalizedValue.slice(0, end).split("\n").length - 1
          }
        }
      }
    }

    return {
      start: start,
      end: end
    }
  }

}
