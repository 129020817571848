// This example controller works with specially annotated HTML like:
//
//  <h4>Tasks</h4>
//  <div data-controller="nested-form">
//    <template data-nested-form-target="template">
//      <%= form.fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task| %>
//        <%= render "task_fields", form: task %>
//      <% end %>
//    </template>
//
//    <%= form.fields_for :tasks do |task| %>
//      <%= render "task_fields", form: task %>
//    <% end %>
//
//    <div class="mb-3" data-nested-form-target="links">
//      <%= link_to "Add Task", "#", class: "btn btn-outline-primary", data: { action: "click->nested-form#add_association" } %>
//    </div>
//  </div>
//
//  # _task_fields.html.erb
//  <%= content_tag :div, class: "nested-fields", data: { new_record: form.object.new_record? } do %>
//    <div class="form-group">
//      <%= form.label :description %>
//      <%= form.text_field :description, class: 'form-control' %>
//      <small><%= link_to "Remove", "#", data: { action: "click->nested-form#remove_association" } %></small>
//    </div>
//
//    <%= form.hidden_field :_destroy %>
//  <% end %>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "select" ]
  static values = {
    wrapperClass: { type: String, default: "nested-fields" },
    elementClass: { type: String, default: "nested-field" },
    newRecordIdentifier: { type: String, default: "NEW_RECORD" }
  }

  add_association(event) {
    event.preventDefault()

    let target

    if (event.params.templateId) {
      target = document.getElementById(event.params.templateId)
    } else if (this.hasSelectTarget) {
      if (this.selectTarget.value === "") {
        return
      }
      target = document.getElementById(this.selectTarget.value)
    } else {
      target = this.templateTarget
    }

    const regex = new RegExp(this.newRecordIdentifierValue, "g")
    const content = target.innerHTML.replace(regex, new Date().getTime())

    if (event.params.insertAfterId) {
      const insertAfter = document.getElementById(event.params.insertAfterId)
      insertAfter.insertAdjacentHTML("afterend", content)
      if (event.params.scroll) {
        "scrollBehavior" in document.documentElement.style ?
          insertAfter.nextElementSibling.scrollIntoView({ behavior: "smooth" }) :
          insertAfter.nextElementSibling.scrollIntoView(true)
      }
    } else {
      this.linksTarget.insertAdjacentHTML("beforebegin", content)
      if (event.params.scroll) {
        "scrollBehavior" in document.documentElement.style ?
          this.linksTarget.scrollIntoView({ behavior: "smooth" }) :
          this.linksTarget.scrollIntoView(true)
      }
    }

    this.dispatch("added")
  }

  remove_association(event) {
    event.preventDefault()

    const wrapper = event.target.closest("." + this.wrapperClassValue)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
      wrapper.querySelectorAll("." + this.elementClassValue).forEach(item => item.remove())
    }

    this.dispatch("removed", { detail: { wrapper: wrapper } })
  }
}
