import { Controller } from "@hotwired/stimulus"
import {enter, leave} from "el-transition"

export default class extends Controller {
  static targets = ["menu", "menuArrow"]
  static values = {
    closeOnOutsideClick: { type: Boolean, default: true }
  }

  connect() {
    this.closeIfClickedOutside = this.closeIfClickedOutside.bind(this)

    if (this.closeOnOutsideClickValue) {
      window.addEventListener("click", this.closeIfClickedOutside)
    }
  }

  disconnect() {
    window.removeEventListener("click", this.closeIfClickedOutside)
  }

  toggleSubMenu() {
    if(this.subMenuOpened) {
      this.closeSubMenu()
    } else {
      this.openSubMenu()
    }
  }

  openSubMenu() {
    if(this.subMenuOpened) {
      return
    }

    this.menuTargets.forEach((t) => {
      enter(t)
    })
    this.toggleMenuArrows()
  }

  closeSubMenu() {
    if(!this.subMenuOpened) {
      return
    }

    this.menuTargets.forEach((t) => {
      leave(t)
    })
    this.toggleMenuArrows()
  }

  closeIfClickedOutside(event) {
    // Ignore event if clicked within element
    if (!this.subMenuOpened || this.element === event.target || this.element.contains(event.target)) {
      return
    }

    this.closeSubMenu()
  }

  toggleMenuArrows() {
    if (this.hasMenuArrowTarget) {
      this.menuArrowTargets.forEach((target) => target.classList.toggle("hidden"))
    }
  }

  get subMenuOpened() {
    if (!this.hasMenuTarget) {
      return false
    }

    return !this.menuTarget.classList.contains("hidden")
  }
}
