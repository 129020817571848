import Modal from "./modal_controller"

export default class extends Modal {
  static targets = ["container", "image"]

  setAndOpen(event) {
    const slide = event.currentTarget
    let src

    if (event.params.src) {
      src = event.params.src
    } else {
      src = slide.querySelector("img").src
    }

    this.imageTarget.src = src
    this.open(event)
  }
}
