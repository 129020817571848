import Flatpickr from "stimulus-flatpickr"
import { get } from "@rails/request.js"

export default class extends Flatpickr {
  async setToNow(event) {
    if (event) {
      event.preventDefault()
    }

    const response = await get("/admin/contents/now")
    if (response.ok) {
      const responseBody = await response.text
      this.fp.setDate(responseBody, true)
    }
  }
}
