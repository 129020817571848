import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["indexInput", "element", "upArrow", "downArrow", "topArrow", "bottomArrow", "indexDisplay"]

  initialize() {
    this.defer = true
  }

  connect() {
    this.applyIndexes()
    this.displayIndexes()
    this.defer = false
  }

  disconnect() {
    this.defer = true
  }

  elementTargetConnected(_element) {
    if (this.defer) {
      return
    }

    this.applyIndexes()
    this.sortEnd()
    this.displayIndexes()
  }

  elementTargetDisconnected(_element) {
    if (this.defer) {
      return
    }

    this.applyIndexes()
    this.sortEnd()
    this.displayIndexes()
  }

  moveUp(event) {
    event.preventDefault()

    if (event.params.index <= 0) {
      return
    }

    const selected = this.elementTargets[event.params.index]
    const nextElm = this.elementTargets[event.params.index - 1]
    this.defer = true
    if (selected && nextElm) {
      selected.parentNode.insertBefore(selected, nextElm)
      this.applyIndexes()
      this.sortEnd()
      this.displayIndexes()
      if (event.params.scroll) {
        this.scrollToIndex(event.params.index - 1)
      }
    }
    setTimeout(() => { this.defer = false }, 500)
  }

  moveTop(event) {
    event.preventDefault()

    if (event.params.index <= 0) {
      return
    }

    let currentIndex = event.params.index

    this.defer = true
    while (currentIndex >= 0) {
      const selected = this.elementTargets[currentIndex]
      const nextElm = this.elementTargets[currentIndex - 1]
      if (selected && nextElm) {
        selected.parentNode.insertBefore(selected, nextElm)
      }
      currentIndex -= 1
    }
    this.applyIndexes()
    this.sortEnd()
    this.displayIndexes()
    setTimeout(() => { this.defer = false }, 500)
    if (event.params.scroll) {
      this.scrollToIndex(0)
    }
  }

  moveDown(event) {
    event.preventDefault()

    const totalTargets = this.elementTargets.length

    if (event.params.index >= totalTargets - 1) {
      return
    }

    const selected = this.elementTargets[event.params.index]
    const nextElm = this.elementTargets[event.params.index + 1]
    this.defer = true
    if (selected && nextElm) {
      selected.parentNode.insertBefore(nextElm, selected)
      this.applyIndexes()
      this.sortEnd()
      this.displayIndexes()
      if (event.params.scroll) {
        this.scrollToIndex(event.params.index + 1)
      }
    }
    setTimeout(() => { this.defer = false }, 500)
  }

  moveBottom(event) {
    event.preventDefault()

    const totalTargets = this.elementTargets.length

    if (event.params.index >= totalTargets - 1) {
      return
    }

    let currentIndex = event.params.index

    this.defer = true
    while (currentIndex < totalTargets) {
      const selected = this.elementTargets[currentIndex]
      const nextElm = this.elementTargets[currentIndex + 1]
      if (selected && nextElm) {
        selected.parentNode.insertBefore(nextElm, selected)
      }
      currentIndex += 1
    }
    this.applyIndexes()
    this.sortEnd()
    this.displayIndexes()
    setTimeout(() => { this.defer = false }, 500)
    if (event.params.scroll) {
      this.scrollToIndex(this.elementTargets.length - 1)
    }
  }

  scrollToIndex(index) {
    const element = this.elementTargets[index]
    "scrollBehavior" in document.documentElement.style ?
      element.scrollIntoView({ behavior: "smooth" }) :
      element.scrollIntoView(true)
  }

  applyIndexes() {
    this.elementTargets.forEach((elm, index) => {
      if (this.hasUpArrowTarget && this.upArrowTargets[index]) {
        this.upArrowTargets[index].dataset.sortingIndexParam = index
      }
      if (this.hasDownArrowTarget && this.downArrowTargets[index]) {
        this.downArrowTargets[index].dataset.sortingIndexParam = index
      }
      if (this.hasTopArrowTarget && this.topArrowTargets[index]) {
        this.topArrowTargets[index].dataset.sortingIndexParam = index
      }
      if (this.hasBottomArrowTarget && this.bottomArrowTargets[index]) {
        this.bottomArrowTargets[index].dataset.sortingIndexParam = index
      }
    })
  }

  sortEnd() {
    if (this.hasIndexInputTarget) {
      this.indexInputTargets.forEach((elm, index) => {
        elm.value = index + 1
      })
    }
  }

  displayIndexes() {
    if (this.hasIndexDisplayTarget) {
      this.indexDisplayTargets.forEach((elm, index) => {
        elm.innerHTML = index + 1
      })
    }
  }
}
