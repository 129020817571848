import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  receive(event) {
    event.preventDefault()

    this.targetId = event.detail.targetId

    this.dispatch("open-modal", { target: window })
  }

  select(event) {
    event.preventDefault()

    const detail = {
      attachmentId: event.params.id,
      url: event.params.url,
      filename: event.params.filename,
      targetId: this.targetId
    }

    this.dispatch("item-selected", { target: window, detail })
    this.dispatch("close-modal", { target: window })
  }
}
