import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["element", "item", "indexDisplay", "indexInput"]
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: "position"
    },
    animation: Number,
    handle: String,
    draggable: String
  }


  initialize () {
    this.end = this.end.bind(this)
  }

  connect() {
    let element = this.element
    if (this.hasElementTarget) {
      element = this.elementTarget
    }

    this.sortable = new Sortable(element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  itemTargetConnected(_element) {
    this.allCallbacks()
  }

  itemTargetDisconnected(_element) {
    this.allCallbacks()
  }

  end(_event) {
    if (this.hasIndexInputTarget) {
      this.indexInputTargets.forEach((elm, index) => {
        elm.value = index + 1
      })
    }
  }

  applyIndexes() {
  }

  displayIndexes() {
    if (this.hasIndexDisplayTarget) {
      this.indexDisplayTargets.forEach((elm, index) => {
        elm.innerHTML = index + 1
      })
    }
  }

  allCallbacks() {
    this.applyIndexes()
    this.end()
    this.displayIndexes()
  }

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      draggable: this.draggableValue || this.defaultOptions.draggable || undefined,
      onEnd: this.end
    }
  }

  get defaultOptions() {
    return {}
  }
}
