import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  emitSelect() {
    const detail = {
      value: this.element.value,
      text: this.element.options[this.element.selectedIndex].text
    }

    this.dispatch("item-selected", { target: window, detail })
  }

  resetIfSelected(event) {
    if (event.detail.value == this.element.value) {
      this.element.selectedIndex = 0
    }
  }
}
