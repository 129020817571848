import { Controller } from "@hotwired/stimulus"
// import { SwiperOptions } from "swiper"
import Swiper from "swiper/bundle"

export default class extends Controller {
  static targets = ["fullImage"]
  static values = {
    options: Object,
    identifier: { type: String, default: "swipid" }
  }

  connect() {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions() {
    return {
      navigation: {
        nextEl: `.swiper-button-next-${this.identifierValue}`,
        prevEl: `.swiper-button-prev-${this.identifierValue}`,
      },
      pagination: {
        el: `.swiper-pagination-${this.identifierValue}`,
        clickable: true,
        dynamicBullets: false,
        renderBullet: function (index, className) {
          return "<span class=\"" + className + "\">" + (index + 1) + "</span>"
        }
      }
    }
  }
}
