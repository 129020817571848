import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "actionLink", "replyTemplate", "removeTemplate", "reportTemplate", "warningTemplate" ]
  static values = {
    activeLinkClass: { type: String, default: "active" }
  }

  jumpToLocation(event) {
    const url = new URL(window.location)

    if (url.hash && url.hash === "#comments") {
      return
    }

    if (url.hash || !url.search) {
      if (url.hash && document.getElementById(url.hash.slice(1))) {
        "scrollBehavior" in document.documentElement.style ?
          document.querySelector(url.hash).scrollIntoView({ behavior: "smooth" }) :
          document.getElementById(url.hash.slice(1)).scrollIntoView(true)
      }
      return
    }

    const hashes = url.search.slice(url.search.indexOf("?") + 1).split("&")
    const urlParams = {}
    hashes.map(hash => {
      const [key, val] = hash.split("=")
      urlParams[key] = decodeURIComponent(val)
    })

    if (urlParams.jump) {
      setTimeout(() => {
        if (document.getElementById(urlParams.jump)) {
          "scrollBehavior" in document.documentElement.style ?
            document.getElementById(urlParams.jump).scrollIntoView({ behavior: "smooth" }) :
            document.getElementById(urlParams.jump).scrollIntoView(true)
        }
      }, 100)
    }
  }

  addReply(event) {
    event.preventDefault()

    if (!this.hasReplyTemplateTarget || !event.params.inputTarget || event.target.classList.contains(this.activeLinkClassValue)) {
      return
    }

    const target = document.getElementById(event.params.inputTarget)

    if (!target) {
      return
    }

    const rootCommentId = event.params.rootCommentId
    const parentCommentId = event.params.parentCommentId
    const repliedToCommentId = event.params.repliedToCommentId

    const template = this.replyTemplateTarget

    const parsedTemplate = new DOMParser().parseFromString(template.innerHTML, "text/html").body.firstElementChild
    const form = parsedTemplate.querySelector("form")

    if (rootCommentId) {
      const elm = document.createElement("input")
      elm.type = "hidden"
      elm.name = "comment[root_comment_id]"
      elm.value = rootCommentId
      form.appendChild(elm)
    }

    if (parentCommentId) {
      const elm = document.createElement("input")
      elm.type = "hidden"
      elm.name = "comment[parent_comment_id]"
      elm.value = parentCommentId
      form.appendChild(elm)
    }

    if (repliedToCommentId) {
      const elm = document.createElement("input")
      elm.type = "hidden"
      elm.name = "comment[replied_to_comment_id]"
      elm.value = repliedToCommentId
      form.appendChild(elm)
    }

    const content = parsedTemplate.outerHTML
    target.innerHTML = ""
    target.insertAdjacentHTML("afterbegin", content)

    this.resetEdit(event)
  }

  addRemove(event) {
    event.preventDefault()

    if (!this.hasRemoveTemplateTarget || !event.params.inputTarget || !event.params.commentId || event.target.classList.contains(this.activeLinkClassValue)) {
      return
    }

    const target = document.getElementById(event.params.inputTarget)

    if (!target) {
      return
    }

    const commentId = event.params.commentId
    const template = this.removeTemplateTarget
    const parsedTemplate = new DOMParser().parseFromString(template.innerHTML, "text/html").body.firstElementChild
    const form = parsedTemplate.querySelector("form")

    let formAction = form.action
    formAction = `${form.action}/${commentId}`
    form.action = formAction

    const content = parsedTemplate.outerHTML
    target.innerHTML = ""
    target.insertAdjacentHTML("afterbegin", content)

    this.resetEdit(event)
  }

  addWarning(event) {
    event.preventDefault()

    if (!this.hasWarningTemplateTarget || !event.params.inputTarget || !event.params.commentId || event.target.classList.contains(this.activeLinkClassValue)) {
      return
    }

    const target = document.getElementById(event.params.inputTarget)

    if (!target) {
      return
    }

    const commentId = event.params.commentId
    const userId = event.params.userId
    const template = this.warningTemplateTarget
    const parsedTemplate = new DOMParser().parseFromString(template.innerHTML, "text/html").body.firstElementChild
    const form = parsedTemplate.querySelector("form")
    const userEditLink = parsedTemplate.querySelector(".user-edit-link")

    if (userEditLink) {
      userEditLink.href = `/users/${userId}/edit`
    }

    let formAction = form.action
    formAction = `${form.action}/${commentId}/warn`
    form.action = formAction

    const content = parsedTemplate.outerHTML
    target.innerHTML = ""
    target.insertAdjacentHTML("afterbegin", content)

    this.resetEdit(event)
  }

  addReport(event) {
    event.preventDefault()

    if (!this.hasReportTemplateTarget || !event.params.inputTarget || !event.params.commentId || event.target.classList.contains(this.activeLinkClassValue)) {
      return
    }

    const target = document.getElementById(event.params.inputTarget)

    if (!target) {
      return
    }

    const commentId = event.params.commentId
    const template = this.reportTemplateTarget
    const parsedTemplate = new DOMParser().parseFromString(template.innerHTML, "text/html").body.firstElementChild
    const form = parsedTemplate.querySelector("form")

    let formAction = form.action
    formAction = `${form.action}/${commentId}/report`
    form.action = formAction

    const content = parsedTemplate.outerHTML
    target.innerHTML = ""
    target.insertAdjacentHTML("afterbegin", content)

    this.resetEdit(event)
  }

  resetEdit(event) {
    if (!event.params.bodyTarget) {
      return
    }

    const target = document.getElementById(event.params.bodyTarget)

    if (!target) {
      return
    }

    if (target.querySelector("form")) {
      let newSrc = target.src
      newSrc = newSrc.replace(/\/edit/, "")
      target.src = newSrc
    }
  }

  clearTarget(event) {
    if (!event.params.clearTarget) {
      return
    }

    const target = document.getElementById(event.params.clearTarget)

    if (!target) {
      return
    }

    target.innerHTML = ""
  }

  remove_association(event) {
    event.preventDefault()

    const wrapper = event.target.closest("." + this.wrapperClassValue)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
      wrapper.querySelectorAll("." + this.elementClassValue).forEach(item => item.remove())
    }
  }
}
