
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "field"]

  showhide(event) {
    event.preventDefault()
    if (this.fieldTarget.type === "password") {
      //this.value.textContent = "hide"
      this.fieldTarget.type = "text"
    } else {
      //this.value.textContent = "show"
      this.fieldTarget.type = "password"
    }

    if (this.hasToggleTarget) {
      this.toggleTargets.forEach(target => {
        target.classList.toggle("hidden")
      })
    }
  }
}
