import Chart from "./chart_controller"

/*
  Same as chart_controller, but is set to always be a line chart.
  Includes some additional default options.
  Maintains the same functionality as chart_controller.
  <div
    data-controller="line-chart"
    data-line-chart-data-value="{ ... }"
    data-line-chart-options-value="{ ... }"
  >
    <canvas data-line-chart-target="canvas"></canvas>
  </div>
*/
export default class extends Chart {
  connect() {
    this.typeValue = "line"
    super.connect()
  }

  get chartData() {
    const ctx = this.canvasTarget.getContext("2d")
    let finalData = this.dataValue
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, "rgba(159, 12, 24, 0.5)")
    gradient.addColorStop(0.5, "rgba(159, 12, 24, 0)")
    gradient.addColorStop(1, "rgba(159, 12, 24, 0)")

    if (this.hasDataValue) {
      let dataset = this.dataValue.datasets[0]
      const moreOptions = {
        backgroundColor: function(context) {
          const chart = context.chart
          const {chartArea} = chart

          if (!chartArea) {
            // This case happens on initial chart load
            return
          }
          return gradient
        },
        borderColor: ["rgba(159, 12, 24)"],
        tension: 0.2,
        fill: true
      }
      dataset = { ...dataset, ...moreOptions }

      finalData = { ...this.dataValue, ...{ datasets: [dataset] } }
    }

    return finalData
  }
}
