import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["attachmentId", "blank", "filename", "image", "previewLink"]
  static values = {
    identifier: String,
    url: String
  }

  connect() {
    this.identifierValue = [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join("")
    this.domIdentifier = `attachment-select-${this.identifierValue}`
    this.element.id = this.domIdentifier

    if (this.urlValue === "") {
      this.imageTarget.classList.add("hidden")
    }
  }

  openManager(event) {
    event.preventDefault()

    this.dispatch("launch", { detail: { targetId: this.domIdentifier } })
  }

  receive(event) {
    if (event.detail.targetId !== this.domIdentifier) {
      return
    }

    if (this.hasFilenameTarget) {
      this.filenameTarget.innerHTML = event.detail.filename
    }
    this.imageTarget.src = event.detail.url
    this.urlValue = event.detail.url
    this.attachmentIdTarget.value = event.detail.attachmentId
    if (this.hasPreviewLinkTarget) {
      this.previewLinkTarget.href = `/admin/attachments/${event.detail.attachmentId}/preview`
    }
    this.blankTarget.classList.add("hidden")
    this.imageTarget.classList.remove("hidden")
  }
}
