import { Controller } from "@hotwired/stimulus"

/*
  Exmaple markup:

  <div data-controller="template-select">
    <select data-action="change->template-select#select" data-template-select-target="selector">
      <option value="Value1">Thing 1</option>
      <option value="Value2">Thing 2</option>
    </select>

    <template id="template-select-Value1">
      <!-- Markup -->
    </template>

    <template id="template-select-Value2">
      <!-- Markup -->
    </template>

    <div data-template-select-target="insertArea"></div>
  </div>
*/

export default class extends Controller {
  static targets = [ "selector", "insertArea" ]
  static values = { selectType: { type: String, default: "select" } }

  connect() {
    if (this.hasSelectorTarget) {

      if (this.selectTypeValue === "select") {
        this.displayTemplate(this.selectorTarget.value)
      } else if (this.selectTypeValue === "checkbox") {
        if (this.selectorTarget.checked) {
          this.displayTemplate("1")
        } else {
          this.displayTemplate("0")
        }
      }
    }
  }

  select(event) {
    if (this.selectTypeValue === "select") {
      this.displayTemplate(event.target.value)
    } else if (this.selectTypeValue === "checkbox") {
      if (event.target.checked) {
        this.displayTemplate("1")
      } else {
        this.displayTemplate("0")
      }
    }
  }

  displayTemplate(templateName) {
    templateName = templateName.replaceAll(":", "_")

    const template = this.element.querySelector(`#template-select-${templateName}`)
    if (template) {
      this.insertAreaTarget.innerHTML = template.innerHTML
    } else {
      this.insertAreaTarget.innerHTML = ""
    }
  }
}
