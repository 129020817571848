import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.buildInstance()
  }

  disconnect() {
    this.instance.destroy()
  }

  addItem(event) {
    if (event.detail.value.length > 0) {
      this.instance.addItem(event.detail.value)
    }
  }

  buildInstance() {
    this.instance = new TomSelect(this.element, {
      plugins: {
        remove_button: {
          title:"Remove this item",
        }
      },
      hidePlaceholder: true,
      render: {
        option: (data, escape) => {
          if (data.$option.dataset.type) {
            if (data.$option.dataset.class) {
              return `<div class="my-1">${escape(data.text)} <span class="${data.$option.dataset.class} rounded-sm px-2 py-1">${data.$option.dataset.type}</span></div>`
            }
            return `<div>${escape(data.text)} (${data.$option.dataset.type})</div>`
          }
          return `<div>${escape(data.text)}</div>`
        },
        item: (data, escape) => {
          if (data.$option.dataset.class) {
            return `<div class="${data.$option.dataset.class}">${escape(data.text)}</div>`
          }
          return `<div>${escape(data.text)}</div>`
        }
      }
    })

    this.instance.on("item_remove", (value) => {
      this.dispatch("item-removed", { target: window, detail: { value: value } })
    })
  }
}
