import { Controller } from "@hotwired/stimulus"

/*
  This is a slightly modified version of the work done at:
  https://boringrails.com/articles/responsive-tables-stimulus-intersection-observer/
  Implements a Polaris-like table column scroller for smaller screens
  See ApplicationHelpre#scrollable_table_wrapper for markup
  A table with a thead and a header row containing th elements are required.
  <%= scrollable_table_wrapper do %>
    <table>
      <thead>
        <tr>
          <th>Header</th>
          ...
        </tr>
      <thead>
      <tbody>
        ...
      </tbody>
    </table>
  <% end %>
*/

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in window ||
    "IntersectionObserverEntry" in window ||
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  )
}

export default class TableScrollController extends Controller {
  static targets = ["navBar", "scrollArea", "leftButton", "rightButton", "columnVisibilityIndicator"]
  static classes = ["navShown", "navHidden", "buttonDisabled", "indicatorVisible"]

  connect() {
    this.columns = Array.from(this.element.querySelector("table thead tr").getElementsByTagName("th"))
    this.startObservingColumnVisibility()
  }

  disconnect() {
    this.stopObservingColumnVisibility()
  }

  startObservingColumnVisibility() {
    if (!supportsIntersectionObserver()) {
      console.warn("This browser does not support IntersectionObserver")
      return
    }

    this.intersectionObserver = new IntersectionObserver(
      this.updateScrollNavigation.bind(this),
      {
        root: this.scrollAreaTarget,
        threshold: 0.99, // otherwise, the right-most column sometimes won't be considered visible in some browsers, rounding errors, etc.
      }
    )

    this.columns.forEach((headingEl) => {
      this.intersectionObserver.observe(headingEl)
    })
  }

  stopObservingColumnVisibility() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }

  updateScrollNavigation(observerRecords) {
    observerRecords.forEach((record) => {
      record.target.dataset.isVisible = record.isIntersecting
    })

    this.toggleScrollNavigationVisibility()
    this.updateColumnVisibilityIndicators()
    this.updateLeftRightButtonAffordance()
  }

  toggleScrollNavigationVisibility() {
    const allColumnsVisible =
      this.columns.length > 0 &&
      this.columns[0].dataset.isVisible === "true" &&
      this.columns[this.columns.length - 1].dataset.isVisible === "true"

    if (allColumnsVisible) {
      this.navBarTarget.classList.remove(this.navShownClass)
      this.navBarTarget.classList.add(this.navHiddenClass)
    } else {
      this.navBarTarget.classList.add(this.navShownClass)
      this.navBarTarget.classList.remove(this.navHiddenClass)
    }
  }

  updateColumnVisibilityIndicators() {
    this.columns.forEach((headingEl, index) => {
      const indicator = this.columnVisibilityIndicatorTargets[index]

      if (indicator) {
        indicator.classList.toggle(
          this.indicatorVisibleClass,
          headingEl.dataset.isVisible === "true"
        )
      }
    })
  }

  updateLeftRightButtonAffordance() {
    const firstColumnHeading = this.columns[0]
    const lastColumnHeading = this.columns[this.columns.length - 1]

    this.updateButtonAffordance(
      this.leftButtonTarget,
      firstColumnHeading.dataset.isVisible === "true"
    )
    this.updateButtonAffordance(
      this.rightButtonTarget,
      lastColumnHeading.dataset.isVisible === "true"
    )
  }

  updateButtonAffordance(button, isDisabled) {
    if (isDisabled) {
      button.setAttribute("disabled", "")
      button.classList.add(this.buttonDisabledClass)
    } else {
      button.removeAttribute("disabled")
      button.classList.remove(this.buttonDisabledClass)
    }
  }

  scrollLeft() {
    // scroll to make visible the first non-fully-visible column to the left of the scroll area
    let columnToScrollTo = null
    for (let i = 0; i < this.columns.length; i++) {
      const column = this.columns[i]
      if (columnToScrollTo !== null && column.dataset.isVisible === "true") {
        break
      }
      if (column.dataset.isVisible === "false") {
        columnToScrollTo = column
      }
    }

    this.scrollAreaTarget.scroll(columnToScrollTo.offsetLeft, 0)
  }

  scrollRight() {
    // scroll to make visible the first non-fully-visible column to the right of the scroll area
    let columnToScrollTo = null
    for (let i = this.columns.length - 1; i >= 0; i--) {
      // right to left
      const column = this.columns[i]
      if (columnToScrollTo !== null && column.dataset.isVisible === "true") {
        break
      }
      if (column.dataset.isVisible === "false") {
        columnToScrollTo = column
      }
    }

    this.scrollAreaTarget.scroll(columnToScrollTo.offsetLeft, 0)
  }
}
