import { Controller } from "@hotwired/stimulus"
import {enter, leave} from "el-transition"

export default class extends Controller {
  static targets = ["element"]
  static values = { closeOnOutsideClick: Boolean }

  connect() {
    this.closeIfClickedOutside = this.closeIfClickedOutside.bind(this)

    if (this.closeOnOutsideClickValue) {
      window.addEventListener("click", this.closeIfClickedOutside)
    }
  }

  disconnect() {
    window.removeEventListener("click", this.closeIfClickedOutside)
  }

  toggle() {
    if(this.opened) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if(this.opened) {
      return
    }

    this.elementTargets.forEach((t) => {
      enter(t)
    })
  }

  close() {
    if(!this.opened) {
      return
    }

    this.elementTargets.forEach((t) => {
      leave(t)
    })
  }

  closeIfClickedOutside(event) {
    // Ignore event if clicked within element
    if (!this.opened || this.element === event.target || this.element.contains(event.target)) {
      return
    }

    this.close()
  }

  get opened() {
    return !this.elementTarget.classList.contains("hidden")
  }
}
