import { Controller } from "@hotwired/stimulus"
import {enter, leave} from "el-transition"

export default class extends Controller {
  static targets = ["element"]

  toggle() {
    if(this.opened) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if(this.opened) {
      return
    }

    this.elementTarget.classList.toggle("flex")
    enter(this.elementTarget)
  }

  close() {
    if(!this.opened) {
      return
    }

    leave(this.elementTarget).then(() => this.elementTarget.classList.toggle("flex"))
  }

  get opened() {
    return !this.elementTarget.classList.contains("hidden")
  }
}
