import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import debounce from "../util/debounce"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    frame: { type: String, default: "markdown-preview" },
    type: { type: String, default: "paragraph" }
  }

  initialize() {
    this.performUpdate = this.performRequest.bind(this)
  }

  connect() {
    this.makeRequest = debounce(this.performUpdate, 1100)
    setTimeout(this.performUpdate, 1000)
  }

  update() {
    this.makeRequest()
  }

  performRequest() {
    post("/admin/contents/markdown_preview", {
      body: {
        markdown: this.inputTarget.value,
        frame: this.frameValue,
        type: this.typeValue
      },
      responseKind: "turbo-stream"
    })
  }
}
