import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    this.tabTargets.forEach(tab => {
      if (tab.classList.contains("active")) {
        document.getElementById(tab.dataset.tabbedTargetParam).classList.remove("hidden")
      } else {
        document.getElementById(tab.dataset.tabbedTargetParam).classList.add("hidden")
      }
    })
  }

  change(event) {
    event.preventDefault()

    this.tabTargets.forEach(tab => {
      tab.classList.remove("active")
    })
    event.target.classList.add("active")

    document.querySelectorAll(".tab-content").forEach(panel => {
      panel.classList.add("hidden")
    })
    document.getElementById(event.params.target).classList.remove("hidden")
  }
}
