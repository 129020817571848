import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

/*
  Generic controller to interact with Chart.js
  Defaults to line chart if type is not specified.
  <div
    data-controller="chart"
    data-chart-type="line"
    data-chart-data-value="{ ... }"
    data-chart-options-value="{ ... }"
  >
    <canvas data-chart-target="canvas"></canvas>
  </div>
*/
export default class extends Controller {
  static targets = ["canvas"]
  static values = {
    type: {
      type: String,
      default: "line"
    },
    data: Object,
    options: Object
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    // "sans" font family from resulting Tailwind configuration
    Chart.defaults.font.family = "'Open Sans', sans-serif"

    this.chart = new Chart(element.getContext("2d"), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData() {
    if (!this.hasDataValue) {
      console.warn("[chartjs] You need to pass data as JSON to see the chart.")
    }

    return this.dataValue
  }

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions () {
    return {
      maintainAspectRatio: false
    }
  }
}
