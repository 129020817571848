import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "link" ]
  static values = {
    activeLinkClass: { type: String, default: "active" }
  }

  select(event) {
    this.linkTargets.forEach(link => link.classList.remove(this.activeLinkClassValue))
    event.target.classList.add(this.activeLinkClassValue)
  }
}
