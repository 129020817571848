import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["toggle"]
  static targets = ["element", "secondary"]
  static values = {
    preventEventDefault: { type: Boolean, default: false }
  }

  toggle(event) {
    if (event && this.preventEventDefaultValue) {
      event.preventDefault()
    }
    let className = "hidden"
    if (this.hasToggleClass) {
      className = this.toggleClass
    }

    if (event.params.secondary) {
      if (this.hasSecondaryTarget) {
        this.secondaryTargets.map(target => target.classList.toggle(className))
      }
    } else {
      if (this.hasElementTarget) {
        this.elementTargets.map(target => target.classList.toggle(className))
      }
    }
  }
}
