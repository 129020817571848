import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    name: String,
    preventDefault: { type: Boolean, default: true }
  }

  emit(event) {
    if (this.preventDefaultValue) {
      event.preventDefault()
    }

    document.dispatchEvent(new CustomEvent(this.nameValue))
  }
}
