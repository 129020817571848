import Chart from "./chart_controller"

/*
  Same as chart_controller, but is set to always be a pie chart.
  Includes some additional default options.
  Maintains the same functionality as chart_controller.
  <div
    data-controller="pie-chart"
    data-pie-chart-data-value="{ ... }"
    data-pie-chart-options-value="{ ... }"
  >
    <canvas data-pie-chart-target="canvas"></canvas>
  </div>
*/
export default class extends Chart {
  connect() {
    this.typeValue = "pie"
    super.connect()
  }

  get chartData() {
    const colors = [
      "#9F0C18",
      "#F07012",
      "#CB433F",
      "#C58A00",
      "#09655B",
      "#27AC41",
      "#ABF4ED",
      "#20a796",
      "#4a81c1",
      "#6d4b73"
    ]

    if (this.element.dataset.colorCount) {
      const count = parseInt(this.element.dataset.colorCount)
      Array.from(Array(count)).forEach((_, i) => {
        colors.push("#" + Math.floor(Math.random()*16777215).toString(16))
      })
    }

    if (this.hasDataValue) {
      const dataset = this.dataValue.datasets[0]
      dataset.backgroundColor = colors
      this.dataValue = { ...this.dataValue, ...{ datasets: [dataset] } }
    }

    return this.dataValue
  }
}
