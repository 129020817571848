import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "counter"]
  static values = {
    squish: { type: Boolean, default: true },
    errorClass: { type: String, default: "text-brand-red-500" },
    minimum: Number,
    maximum: Number
  }

  connect() {
    this.update()
  }

  update() {
    const count = this.count.toString()
    let output = count
    if (this.hasMaximumValue) {
      output = output + "/" + this.maximumValue.toString()
    }
    this.counterTarget.innerHTML = output
    if (
      (this.hasMinimumValue && count < this.minimumValue && this.hasErrorClassValue)
      || (this.hasMaximumValue && count > this.maximumValue && this.hasErrorClassValue)
    ) {
      this.counterTarget.classList.add(this.errorClassValue)
    } else if (this.hasErrorClassValue) {
      this.counterTarget.classList.remove(this.errorClassValue)
    }
  }

  get count() {
    if (this.squishValue) {
      return this.inputTarget.value.replace(/\s+/g, " ").trim().length
    } else {
      return this.inputTarget.value.length
    }
  }
}
