import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["currentFilename", "currentCaption", "galleryItem", "currentControlButton"]
  static values = {}

  connect() {
    this.reset()
  }

  select(event) {
    if (event) {
      event.preventDefault()
    }

    this.selectedItem = event.currentTarget
    this.applySelectedItem()
  }

  applySelectedItem() {
    this.selectedHiddenCaptionField = this.selectedItem.querySelector("[data-gallery-item-element='caption']")
    this.selectedImageField = this.selectedItem.querySelector("[data-gallery-item-element='image']")
    this.selectedFilenameField = this.selectedItem.querySelector("[data-gallery-item-element='filename']")

    if (this.selectedImageField) {
      this.currentFilenameTarget.textContent = this.selectedFilenameField.textContent
    } else {
      this.currentFilenameTarget.textContent = ""
    }

    this.galleryItemTargets.forEach((elm) => {
      elm.classList.remove("border-brand-bluegreen")
      elm.dataset.selected = "false"
    })
    this.selectedItem.classList.add("border-brand-bluegreen")
    this.selectedItem.dataset.selected = "true"

    this.currentControlButtonTargets.forEach((elm) => {
      elm.classList.add("hidden")
    })
    this.selectedItem.parentNode.querySelector("a[data-gallery-item-element='select-button']").classList.remove("hidden")
    this.selectedItem.parentNode.querySelector("a[data-gallery-item-element='remove-button']").classList.remove("hidden")

    this.currentCaptionTarget.value = this.selectedHiddenCaptionField.value
  }

  updateCaption() {
    if (this.selectedHiddenCaptionField) {
      this.selectedHiddenCaptionField.value = this.currentCaptionTarget.value
    }
  }

  triggerSelect(event) {
    event.preventDefault()

    if (this.selectedItem) {
      this.selectedItem.parentNode.querySelector("a[data-gallery-item-element='select-button']").click()
    }
  }

  reset(event) {
    if (event) {
      event.preventDefault()
    }

    this.selectedHiddenCaptionField = null
    this.selectedImageField = null
    this.selectedFilenameField = null
    this.selectedItem = null
    this.selectFirstIfNoneSelected()
  }

  selectFirstIfNoneSelected() {
    if (this.galleryItemTargets.every(elm => elm.dataset.selected === undefined || elm.dataset.selected === "false")) {
      this.selectedItem = null
    }

    if (this.selectedItem === null && this.hasGalleryItemTarget) {
      this.selectedItem = this.galleryItemTargets[0]
      this.applySelectedItem()
    }
  }
}
