import { Controller } from "@hotwired/stimulus"

/*
  <div data-controller="set-field">
    <input type="hidden" name="setting" data-set-field-target="field" />
    <input type="submit value="Submit form" data-action="click->set-field#setValue" data-set-field-value-param="value for the field" />
  </div>
*/

export default class extends Controller {
  static targets = ["field"]
  static values = {
    preventDefaultEvent: { type: Boolean, default: false }
  }

  setValue(event) {
    if (this.preventDefaultEventValue) {
      event.preventDefault()
    }

    this.fieldTarget.value = event.params.value
  }
}
