import { Controller } from "@hotwired/stimulus"

/*
  Compares a given width and height to a container and applies a class based on
  if either the width or height would fit in said container.
*/

export default class extends Controller {
  static classes = ["default", "fit"]
  static targets = ["container"]
  static values = { width: Number, height: Number }

  connect() {
    if (this.isLoaded) {
      this.determine()
    }
  }

  determine() {
    setTimeout(() => {
      const containerWidth = this.hasContainerTarget ? this.containerTarget.clientWidth : this.element.clientWidth
      const containerHeight = this.hasContainerTarget ? this.containerTarget.clientHeight : this.element.clientHeight
      const container = this.hasContainerTarget ? this.containerTarget : this.element
      const defaultClassName = this.hasDefaultClass ? this.defaultClass : "object-cover"
      const fitClassName = this.hasFitClass ? this.fitClass : "object-scale-down"

      if (this.hasWidthValue && !isNaN(this.widthValue) && this.hasHeightValue && !isNaN(this.heightValue)) {
        if (this.widthValue < containerWidth || this.heightValue < containerHeight) {
          container.classList.add(fitClassName)
          container.classList.remove(defaultClassName)
        } else {
          container.classList.remove(fitClassName)
          container.classList.add(defaultClassName)
        }
      }
    }, 10)
  }

  get isLoaded() {
    return (this.hasContainerTarget ? this.containerTarget.complete : this.element.complete)
  }
}
